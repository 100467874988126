import sort from 'fast-sort'
import moment from 'moment'
import utils from './utils'
import { PAYMENT_RECEIPT_TYPE, PAYMENT_RECEIPT_STATE, MEMBERSHIP_PRICES } from '@/data/constant'

export default {
  isMembershipExpired (membership) {
    const { expiredAt, membershipType } = membership
    if (membershipType === 'permanent') {
      return false
    }
    return new Date(expiredAt) <= new Date()
  },
  canRegisterNewMembership (memberships) {
    if (memberships.length === 0) {
      return true
    }

    memberships = sort(memberships).desc(m => m.appliedAt)
    const membership = memberships[0]
    if (['draft', 'review'].includes(membership.state)) {
      return false
    }

    if (['draft', 'approved', 'review'].indexOf(membership.state) === -1) {
      // if state is none of these state can register new membership
      // eg terminated resigned, staff can register new member
      return true
    }

    if (
      membership.expiredAt === null ||
      membership.membershipType === 'permanent'
    ) {
      return false
    }
    if (
      membership.expiredAt !== null &&
      !this.isExpiredMoreThanTwoYears(membership)
    ) {
      // membership not expire
      return false
    }

    return true
  },
  canRenewMembership (membership) {
    // membership that has approved status can renew
    if (
      membership &&
      membership.state === 'approved' &&
      membership.membershipType === 'yearly'
    ) {
      if (new Date(membership.expiredAt) <= new Date()) {
        // membership is expired
        // check if longer than 2 years
        const expiredNext2Years = moment(membership.expiredAt)
          .add(2, 'years')
          .add(6, 'months') // add 6 mount for delay renew
          .toDate()
        if (expiredNext2Years <= new Date()) {
          return false
        }
        return true
      }
      return true
    }
    return false
  },
  calculateMembershipExpireDate (applyDate, type) {
    if (type === 'yearly') {
      const date = new Date(applyDate)
      const nextYear = moment(date)
        .add(1, 'years')
        .subtract(1, 'seconds')
      return nextYear.toDate()
    }
    return null
  },
  showResignedAt (membership) {
    if (['draft', 'approved', 'review'].indexOf(membership.state) === -1) {
      return true
    }
    return false
  },
  calculateReceiptAmount (type, startMemberShipDate) {
    const date = new Date(startMemberShipDate)
    let price = MEMBERSHIP_PRICES[MEMBERSHIP_PRICES.length - 1]
    for (let p of MEMBERSHIP_PRICES) {
      if (p.endDate && date.getTime() < p.endDate.getTime()) {
        price = p
        break
      }
    }
    return type === 'yearly' ? price.yearly : price.permanent
  },
  getStartMemberShipDate (expiredAt) {
    return moment(expiredAt).add(1, 'seconds')
  },
  getFullName (member) {
    const fullname = `${member.title} ${member.firstName} ${member.lastName}`
    return fullname
  },
  getContactAddressText (addresses) {
    const contactAddress =
      addresses.length > 1
        ? addresses.find(address => address.addressType === 'contact')
        : addresses.find(address => address.addressType === 'permanent')
    const addressText = utils.buildAddressText(contactAddress)
    return addressText
  },
  createNewReceipt (membership) {
    const appliedAt = membership.appliedAt
    const type = membership.membershipType
    const expiredAt = this.calculateMembershipExpireDate(appliedAt, type)
    const amount = this.calculateReceiptAmount(type, appliedAt)
    const receipt = {
      bookNo: 0,
      receiptNo: 0,
      state: PAYMENT_RECEIPT_STATE.review,
      type: PAYMENT_RECEIPT_TYPE.register,
      membershipType: membership.membershipType,
      receiptDate: membership.appliedAt,
      amount: amount,
      endDate: expiredAt
    }
    if (membership.id) {
      receipt.membershipId = membership.id
    }
    return receipt
  },
  isExpiredMoreThanTwoYears (membership) {
    const isExpired = this.isMembershipExpired(membership)
    const canRenew = this.canRenewMembership(membership)
    return isExpired && !canRenew
  },
  isTheReceiptNew (receipt) {
    const { bookNo, receiptNo } = receipt
    const regex = /(A|OA|KA|B|OB|KB)\d{4}/g
    const found = regex.test(bookNo)
    return found && receiptNo.length === 7
  },
  createNewDocuments () {
    return [
      {
        membershipId: null,
        name: null,
        key: 'idCard',
        label: 'idCard',
        path: null,
        contentType: null,
        extension: null,
        order: 0,
        file: null
      },
      {
        membershipId: null,
        name: null,
        key: 'houseRegistration',
        label: 'houseRegistration',
        path: null,
        contentType: null,
        extension: null,
        order: 1,
        file: null
      },
      {
        membershipId: null,
        name: null,
        key: 'photo',
        label: 'photo',
        path: null,
        contentType: null,
        extension: null,
        order: 2,
        file: null
      },
      {
        membershipId: null,
        name: null,
        key: 'nationalityChanged',
        label: 'nationalityChanged',
        path: null,
        contentType: null,
        extension: null,
        order: 3,
        file: null
      },
      {
        membershipId: null,
        name: null,
        key: 'nameChanged',
        label: 'nameChanged',
        path: null,
        contentType: null,
        extension: null,
        order: 4,
        file: null
      },
      {
        membershipId: null,
        name: null,
        key: 'signature',
        label: 'signature',
        path: null,
        contentType: null,
        extension: null,
        order: 5,
        file: null
      }
    ]
  },
  buildStateForMembershipDocument (documentArray) {
    const documents = documentArray ? [...documentArray] : []
    let tmpDocuments = this.createNewDocuments()
    if (!documents.length) {
      return tmpDocuments
    }
    const otherDocuments = []
    for (const doc of documents) {
      const index = tmpDocuments.findIndex(td => td.key === doc.key)
      if (index !== -1) {
        tmpDocuments[index] = Object.assign({}, tmpDocuments[index], doc, { order: tmpDocuments[index].order })
      } else {
        otherDocuments.push(doc)
      }
    }

    return [...tmpDocuments, ...otherDocuments]
  }
}
