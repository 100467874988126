import Vue from 'vue'
import store from '@/store'
// import { isMobile } from 'mobile-device-detect'
import Router from 'vue-router'
import NProgress from 'nprogress'
import authenticate from '@/auth/authenticate'
import membership from '@/routers/membership'
import setting from '@/routers/setting'
import staff from '@/routers/staff'
import profile from '@/routers/profile'
import report from '@/routers/report'
import waitingMember from '@/routers/waitingMember'
import error from '@/routers/error'
import paymentReceipt from '@/routers/paymentReceipt'
import mobileAgent from '@/routers/mobileAgent'
import onlineChannel from '@/routers/onlineChannel'
import familyMembership from '@/routers/familyMembership'
import requestRefund from '@/routers/requestRefund'
import memberLevel from '@/routers/memberLevel'
import memberGroup from '@/routers/memberGroup'
import { STAFF_ROLES } from '@/data/constant'

Vue.use(Router)

// create new router

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/app'), // webpackChunkName app
    beforeEnter: authenticate,
    redirect: () => {
      if (!store.getters['auth/isAuthenticated']) {
        return 'auth/signin'
      }

      switch (store.getters['auth/role']) {
        case STAFF_ROLES.accounting:
          return 'payment-receipt/dashboard'
        case STAFF_ROLES.area_agent_viewer:
          return { name: 'membership-group', query: { role: STAFF_ROLES.area_agent_viewer } }
        case STAFF_ROLES.branch_committee_viewer:
          return { name: 'membership-group', query: { role: STAFF_ROLES.branch_committee_viewer } }
        default:
          return 'membership'
      }
    },

    children: [
      {
        path: 'dashboard',
        component: () => import('@/layouts/dashboard'), // dashboard
        children: [
          {
            path: '',
            component: () => import('@/views/dashboard/Index')
          }
        ]
      },
      membership,
      staff,
      paymentReceipt,
      profile,
      report,
      waitingMember,
      mobileAgent,
      onlineChannel,
      familyMembership,
      requestRefund,
      memberLevel,
      ...setting,
      memberGroup,
      {
        path: '/payment',
        component: () => import('@/layouts/default'),
        children: [
          {
            name: 'payment-index',
            path: '',
            component: () => import('@/views/payment/Index')
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/layouts/auth'), // webpackChunkName app
    children: [
      {
        name: 'signin',
        path: 'signin',
        component: () => import('@/views/auth/SignIn')
      }
    ]
  },
  ...error,
  {
    name: 'test',
    path: '/test',
    component: () => import('@/views/test/Index')
  }

  // sessions

  // {
  //   path: '*',
  //   component: () => import('@/views/app/pages/notFound')
  // }
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (to.path) {
    // Start the route progress bar.
    NProgress.start()
    NProgress.set(0.1)
  }

  // If the user already authenticated and tries to access the sing in page, redirect to the home page
  if (to.name === 'signin' && isAuthenticated) {
    next({ path: '/' })
  }

  if (to.meta && to.meta.acl) {
    const acl = to.meta.acl
    if (!isAuthenticated) {
      next({ name: 'signin' })
    } else if (!Vue.prototype.$acl.validate(acl)) {
      next({ name: 'error-403' })
    }
  }

  // If this isn't an initial page load.
  next()
})

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById('loading_wrap')
  if (gullPreLoading) {
    gullPreLoading.style.display = 'none'
  }
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500)
  // NProgress.done();
  // if (isMobile) {
  if (window && window.innerWidth <= 1200) {
    store.dispatch('changeSidebarProperties')
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch('changeSecondarySidebarProperties')
    }

    // if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
    //   store.dispatch('changeCompactSidebarProperties')
    // }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch('changeSecondarySidebarProperties')
    }

    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  }
})

export default router
