export default (http) => ({
  findAll (query) {
    const params = Object.assign({}, query)
    return http.get('online-channel-setting', { params })
      .then(response => response.data)
  },
  update (id, model) {
    return http.put(`online-channel-setting/${id}`, model)
      .then(response => response.data)
  },
  bulkUpdate (models) {
    return http.put(`online-channel-setting/bulk`, models)
      .then(response => response.data)
  }
})
