export default {
  name: 'ใบเสร็จรับเงิน',
  pendingPaymentReceipt: 'ใบเสร็จรับเงินที่รอการชำระ',
  allReceipts: 'ใบเสร็จรับเงินทั้งหมด',
  status: 'สถานะ',
  receiveMoney: 'ได้รับเงินจาก',
  amount: 'จำนวนเงิน (บาท)',
  amountText: 'จำนวนเงิน (ตัวอักษร)',
  sum: 'รวมเงิน',
  pay: 'ชำระเงินเรียบร้อย',
  membershipFee: 'ค่าบำรุงพรรคแบบ',
  membershipType: 'ประเภทสมาชิก',
  paymentReceiptType: 'ประเภทใบเสร็จ',
  printReceipt: 'พิมพ์ใบเสร็จรับเงิน',
  reject: 'ไม่ผ่านการตรวจสอบ',
  reason: 'เหตุผล',
  noReceipt: 'ไม่มีใบเสร็จ',
  waitingForReview: 'กำลังรอการออกใบเสร็จ',
  deleteReceipt: 'ลบใบเสร็จรับเงิน',
  sendToReceiptAgain: 'ส่งไปออกใบเสร็จอีกครั้ง',
  pendingPaymentReceiptMembership: 'รอการตรวจสอบใบเสร็จ',
  cancelReceipt: 'ยกเลิกใบเสร็จ',
  reasonToCancelReceipt: 'เหตุผลในการยกเลิก',
  confirmCancelReceipt: 'ยืนยันการยกเลิกใบเสร็จ',
  dialog: {
    title: 'ชำระเงินเรียบร้อย',
    message: 'ยืนยันการชำระเงินเรียบร้อย'
  },
  state: {
    all: 'ทั้งหมด',
    draft: 'แบบร่าง',
    review: 'รอการตรวจสอบ',
    paid: 'ชำระเงินแล้ว',
    approved: 'อนุมัติ',
    rejected: 'ไม่อนุมัติ',
    cancel: 'ยกเลิก'
  },
  type: {
    register: 'การสมัครสมาชิกพรรค',
    renew: 'การต่ออายุสมาชิกพรรค',
    null: 'null'
  },
  typeShort: {
    register: 'สมัครใหม่',
    renew: 'ต่ออายุ',
    null: 'null'
  },
  dashboard: {
    name: 'แผงควบคุมใบเสร็จรับเงิน',
    numberOfApprovedToday: 'จำนวนที่อนุมัติวันนี้',
    numberOfRemainingReview: 'จำนวนที่รอการออก',
    numberOfApprovedThisYear: 'จำนวนที่อนุมัติปีนี้',
    numberOfEarningThisYear: 'จำนวนรายได้ในปีนี้',
    monthlySummaryApproved: 'รายงานใบเสร็จที่ออกในปี',
    dailySummaryApproved: 'รายงานใบเสร็จที่ออกในเดือน',
    baht: 'บาท',
    receipt: 'ใบเสร็จ',
    amount: 'จำนวนเงิน',
    amountOfReceipt: 'จำนวนใบเสร็จ',
    register: 'สมัครใหม่',
    renew: 'ต่ออายุ',
    yearly: 'แบบรายปี',
    permanent: 'แบบตลอดชีพ',
    approvedAt: 'ที่ออกใน',
    choose: {
      membershipType: 'เลือกระยะเวลาสมาชิก',
      type: 'เลือกชนิดใบเสร็จ'
    }
  },
  reportDuplicate: 'รายงานใบเสร็จที่ซ้ำกัน',
  bookNo: 'เล่มที่',
  receiptNo: 'เลขที่',
  save: 'บันทึกใบเสร็จรับเงิน',
  savePaymentReceiptSuccess: 'บันทึกใบเสร็จรับเงินสำเร็จ',
  pleaseApproveThePreviousReceipt: 'กรุณาอนุมัติใบเสร็จก่อนหน้า',
  fullName: 'ชื่อ - นามสกุล',
  printedAt: 'พิมพ์เมื่อวันที่',
  printMultiple: {
    title: 'พิมพ์ใบเสร็จรับเงินเป็นชุด',
    status: 'สถานะการพิมพ์',
    saveAsFile: 'บันทึกเป็นไฟล์ pdf',
    wasPrinted: 'พิมพ์แล้ว',
    wasNotPrinted: 'ยังไม่พิมพ์',
    markAsPrinted: 'ทำเครื่องหมายว่าพิมพ์ไปแล้ว',
    markAsNotPrint: 'ทำเครื่องหมายว่ายังไม่พิมพ์',
    saveAsFileFailed: 'ไม่สามารถบันทึกไฟล์ pdf ได้ กรุณาลองใหม่อีกครั้ง',
    markAsPrintedFailed: 'ไม่สามารถทำเครื่องหมายว่าพิมพ์ไปแล้วได้ กรุณาลองใหม่อีกครั้ง',
    markAsNotPrintFailed: 'ไม่สามารถทำเครื่องหมายว่ายังไม่พิมพ์ได้ กรุณาลองใหม่อีกครั้ง',
    selectedAllPaymentReceipt: 'เลือกใบเสร็จรับเงินทั้งหมด {count} ในหน้านี้แล้ว',
    selectedAllPaymentReceiptLink: 'เลือกใบเสร็จรับเงินทั้งหมด {count} รายการ',
    clearSelected: 'ล้างที่เลือก',
    overPrintLimit: 'ไม่สามารถทำรายการเกิน {count} รายการได้'
  }
}
